<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <!--顶部显示区域-->
      <div class="top-info">
        <i class="el-icon-caret-left" @click="upperPage"></i>
        <h2>{{title}}</h2>
        <div class="status-button">
          <span ref="status">{{statusName(eventDetail.status)}}</span>
<!--          <el-button
            @click="refundAll"
            v-if="initPayDetail.status === 2"
            class="refund-button"
          >
            {{ $t("RefundAll") }}
          </el-button>-->
          <el-button @click="editActivityDialog = true" :disabled="Number(eventDetail.status)!==1">{{ $t("EditActivity") }}</el-button>
        </div>
      </div>
      <!--搜索框-->
      <div class="filterBarPay">
        <!--ID-->
<!--        <div>
          <label>Id：</label>
          <input
            type="number"
            v-model.trim="activityId"
          >
        </div>-->
        <!--公司名-->
        <div>
          <label>{{ $t("CompanyName") }}：</label>
          <input
              type="text"
              v-model.trim="companyName"
          >
        </div>
        <!--缴费人-->
        <div>
          <label>{{ $t("PayPerson") }}：</label>
          <input
            type="text"
            v-model.trim="payer"
          >
        </div>
        <!--缴费时间-->
<!--        <div>
          <label>{{$t('PayTime')}}：</label>
          <el-date-picker
            v-model="createTime"
            type="date"
            :placeholder="$t('ChooseDate')"
            format="yyyy-MM-dd">
          </el-date-picker>
        </div>-->
        <el-button @click="searchUserData">{{ $t('Search') }}</el-button>
      </div>
      <!--  总金额-->
      <div class="sum-info">
        <span>{{$t('AllIncome') + initPayDetail.totalPrice}}</span>
        <span>{{$t('Population') + initPayDetail.totalPeople}}</span>
        <!-- <download-excel
            class="export-excel-wrapper"
            :data="DetailsForm"
            style="float: left; margin-left: 10px"
            :fields="json_fields"
            :header="title"
            type="xlsx"
            :name="title+'.xls'"
        >
          <el-button style="margin: 10px 0; ">{{ $t("ExportExcelData") }}Excel</el-button>
        </download-excel> -->
        <el-button style="margin: 10px 0; " @click="exportExcel">{{ $t("ExportExcelData") }}Excel</el-button>
      </div>
      
      <!--表格-->
      <el-table
        :data="detailList"
        border
        style="width: 99%;margin: 0 auto"
      >
        <!--id-->
<!--        <el-table-column
          prop="paymentId"
          label="Id"
        >
        </el-table-column>-->
        <!--公司名称-->
        <el-table-column
          prop="companyName"
          :label="$t('CompanyName')"
        >
        </el-table-column>
        <!--缴费人-->
        <el-table-column
          prop="payer"
          :label="$t('PayPerson')"
        >
        </el-table-column>
        <!--组别-->
        <el-table-column
          prop="groupName"
          :label="$t('GroupType')"
        >
        </el-table-column>
        <!--Email-->
        <el-table-column
            prop="email"
            label="Email"
        >
        </el-table-column>
        <!--缴费金额-->
        <el-table-column
          prop="price"
          width="165px"
          :label="$t('PayAmount')"
        >
        </el-table-column>
        <!--缴费时间-->
        <el-table-column
          prop="createTime"
          width="165px"
          :label="$t('PayTime')"
        >
        </el-table-column>
        <!--操作-->
<!--        <el-table-column
          prop="operation"
          width="210"
          :label="$t('Operation')"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: red;"
            >{{ $t("Refund") }}
            </el-button>
          </template>
        </el-table-column>-->
      </el-table>
      
      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="userTotal"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>
    
    <!--编辑活动弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('EditActivity')"
      :visible.sync="editActivityDialog"
      append-to-body
      :show-close="false"
      center
    >
      <el-form
        :model="eventDetail"
        ref="createActivity"
        :rules="rules"
        class="create-activity-dialog"
        v-if="editActivityDialog"
      >
        <!--主题-->
        <el-form-item
          :label="$t('Theme')"
          prop="eventTitle"
        >
          <el-input
            v-model="eventDetail.eventTitle"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--地点-->
        <el-form-item
          :label="$t('Address')"
          prop="eventAddress"
        >
          <el-input
            v-model="eventDetail.eventAddress"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--人员-->
        <el-form-item
            :label="$t('People')"
            prop="groupIds"
            class="group-picker"
        >
          <el-cascader
              :options="groups"
              :props="props"
              v-model="groupIds"
              clearable>
            <template slot-scope="{ node, data }">
              <div
                  v-if="node.level === 1"
                  class="select_group"
              >
                <span style="padding-left: 10px">{{ data.label }}</span>
                <span style="color: #D3D3D3">{{data.node}}</span>
                <!--<span style="padding-left: 10px">{{ data.label }}</span>-->
              </div>
            </template>
          </el-cascader>
        </el-form-item>
        <!--活动开始时间-->
        <el-form-item
          :label="$t('ActiveStart')"
          prop="eventDate"
        >
          <el-date-picker
            style="float: left;"
            v-model="eventDetail.eventDate"
            type="datetime"
            :picker-options="pickerOptions"
            :placeholder="$t('ChooseDate')">
          </el-date-picker>
        </el-form-item>
        <!--缴费结束时间-->
        <el-form-item
          :label="$t('PayEnd')"
          prop="activityPaymentTime"
        >
          <el-date-picker
            style="float: left;"
            v-model="eventDetail.activityPaymentTime"
            type="datetime"
            :picker-options="pickerOptions"
            :placeholder="$t('ChooseDate')">
          </el-date-picker>
        </el-form-item>
        <!--内容-->
        <el-form-item
          :label="$t('Content')"
          prop="content"
        >
          <el-input
            type="textarea"
            v-model="eventDetail.content"
            :rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
        <!--费用用途-->
        <el-form-item
          :label="$t('PayMethod')"
        >
          <el-input
            type="textarea"
            v-model="eventDetail.expensePurpose"
            :rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
        <!--个人缴费金额-->
        <el-form-item
          :label="$t('PerPayment')"
          prop="perPay"
        >
<!--          <div class="per-pay">
            <el-select v-model="eventDetail.priceType">
              <el-option
                v-for="item in moneyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input
              v-model.number.trim="eventDetail.eventPrice"
              autocomplete="off"
              id="eventPrice"
              class="pay-amount"
              :placeholder="$t('InputMoney')"
              :disabled="!eventDetail.isCustomize"
            ></el-input>
            <el-switch v-model="eventDetail.isCustomize" @change="customizeOrNot" :active-value="0" :inactive-value="1"></el-switch>
            <span>{{$t('Customize')}}</span>
          </div>-->
  
          <!--  免费活动-->
          <div class="checkbox-overlay" @click="toggleCheckbox"></div>
          <el-checkbox v-model="freeActivity" class="free-activity">{{$t('FreeActivity')}}</el-checkbox>
        </el-form-item>
        <!--补充-->
        <el-form-item
          :label="$t('AdditionInfo')"
        >
          <el-input
            type="textarea"
            v-model="eventDetail.additional"
            :rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelCreate">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          @click="confirmSubmit"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/fetch/api";
//    import axios from 'axios'
import util from '@/assets/js/util'

import mixin from "@/assets/js/mixin";
import moment from "moment/moment";

export default {
  mixins: [mixin],
  data() {
    // 活动金额
    const perPay = (rule, value, callback)=>{
      if(value=== '' || value === null){
        callback(new Error(this.$t('EmptyMoney')))
      }/*else if(this.eventDetail.isCustomize && this.eventDetail.eventPrice===0){
        callback(new Error(this.$t('EmptyMoney')))
      }*/else if(this.eventDetail.isCustomize && this.eventDetail.eventPrice === ""){
        callback(new Error(this.$t('EmptyMoney')))
      }else{
        callback()
      }
    }
    // 参加人员
    const group = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error(value));
      }
      else if (this.groupIds.length === 0) {
        callback(new Error(this.$t("EmptyGroup")));
      } else {
        callback(value);
      }
    };
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      myRight: Number(data.financePermission),
      editActivityDialog: false,
      activeUser: 1,
      chamberId: data.chamberId,
      adminId: data.id,
      detailList: [],
      userTotal: 0,
      pageSize: 15,
      currentPage: 1,
      isNormal: true,
      eventDetail:{},
      eventPrice:0,
      paymentId:this.$route.query.eventId,
      freeActivity:true,
      
      // 搜索用
      companyName: "",
      payer: "",
      createTime: "",
      
      messageText: "",
      
      initDetail: {
        page: 1,
        currentAdmin: data.id,
        paymentId:''
      },
      
      groupIds:[['1699257586647437312'],['1701504692372246528']],
      props:{multiple:true},
      groups:[
        {
          value:'1',
          label:this.$t('AllGroup'),
        }
      ],
      groupIds1:[],
      
      initPayDetail:{
        title:'xx总商会xx商会20xx年迎新春联谊年会',
        status:1,
        totalPrice:0,
        paymentId:''
      },
      
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        }
      },
      
      // 导出EXCEL
      title: '',
      exportList: [],
      json_fields:{},
      DetailsForm:[],
      
      createActiveForm: {
        title: "",
        address: "",
        content: "",
        date: new Date(),
        activityPaymentTime:'',
        payMethod:'',
        perPay:10,
        addOn:'',
        moneyUnit:'USD',
        customize:false,
      },
      
      
      
      moneyOptions:[
        {
          value:'USD',
          label:'USD'
        },
        // {
        //   value:'RP',
        //   label:'RP'
        // },
        // {
        //   value:'RMB',
        //   label:'RMB'
        // },
      ],
      
      rules: {
        eventTitle: [
          { required: true, message: this.$t("EmptyTheme"), trigger: "blur" },
        ],
        eventAddress: [
          { required: true, message: this.$t("EmptyAddress"), trigger: "blur" },
        ],
        groupIds:[
          { required: true, validator: group, trigger: "blur" }
        ],
        eventDate: [
          { required: true, message: this.$t("EmptyDate"), trigger: "blur" },
        ],
        activityPaymentTime: [
          { required: true, message: this.$t("EmptyDate"), trigger: "blur" },
        ],
        content: [
          { required: true, message: this.$t("EmptyContent"), trigger: "blur" },
        ],
        /*perPay: [
          { required: true, validator:perPay, trigger: "change" },
        ],*/
      },
    };
  },
  mounted() {
    this.initDetail.paymentId = this.paymentId
    this.initPayDetail.paymentId = this.paymentId
    this.initDetail.language = this.langToLanguage()
    this.initDetailList(1);
    this.initEventDetail();
    this.getGroups();
    this.json_fields[this.$t('CompanyName')]= "companyName";
    this.json_fields[this.$t('PayPerson')]= "payer";
    this.json_fields['Email']= "email";
    this.json_fields[this.$t('PayAmount')]= "price";
    this.json_fields[this.$t('PayTime')]= "createTime";
  },
  
  updated() {
    if(this.eventDetail.isCustomize){
      // delete this.eventDetail.eventPrice
      // document.getElementById('eventPrice').setAttribute('placeholder',this.$t('Customized'))
    }
  },
  methods: {
    
    //            弹窗：取消
    cancelCreate() {
      this.editActivityDialog = false;
      this.createActiveForm = {
        title: "",
        address: "",
        content: "",
        date: new Date(),
        payMethod:'',
        perPay:'',
        addOn:'',
      };
      this.groupIds = this.groupIds1
    },

    // 导出
    exportExcel() {
      this.initDetail.isExcelNeed  = 1;
      api.getPaymentDetailE(this.initDetail).then(res => {
        util.saveAs(res.data, this.title)
        this.initDetail.isExcelNeed  = 0;
      })
    },
    
    //            初始化用户列表
    initDetailList(currentPage) {
      this.currentPage = currentPage;
      this.initDetail.page = currentPage;
      this.initDetail.isExcelNeed  = 0;
      this.companyName
          ? (this.initDetail.companyName = this.companyName)
          : delete this.initDetail.companyName;
      this.payer
          ? (this.initDetail.payer = this.payer)
          : delete this.initDetail.payer;
      this.createTime
          ? (this.initDetail.createTime = this.createTime)
          : delete this.initDetail.createTime;
      this.initDetail.language = this.langToLanguage()
      api.getPaymentDetail(this.initDetail).then((res) => {
        this.detailList = res.data.paymentList.pageList;
        this.userTotal = res.data.paymentList.total;
        this.initPayDetail = res.data
        this.DetailsForm = res.data.totalData
      });
    },
    
    //            获取活动详情
    initEventDetail() {
      api.getEventDetail({
        eventId:this.paymentId,
      }).then((res) => {
        this.eventDetail = res.data
        this.title = res.data.eventTitle
        this.initPayDetail.title = res.data.eventTitle;
        this.eventDetail.eventDate = moment(this.eventDetail.eventDate)._d
        // this.eventDetail.eventPrice = this.eventDetail.eventPrice ? this.eventDetail.eventPrice : null;
        this.eventPrice = this.eventDetail.eventPrice ? this.eventDetail.eventPrice : null;
        // console.log('this.eventDetail ->',this.eventDetail)
        // this.eventDetail.isCustomize = this.eventDetail.isCustomize===1
        // console.log('moment(this.eventDetail.eventDate)._d ->',moment(this.eventDetail.eventDate)._d)
        // this.eventDetail.isCustomize = this.eventDetail.isCustomize === 1
        if(res.data.groupId.length === 0){
          this.groupIds = [['1']]
          this.groupIds1 = [['1']]
        }else{
          let list = []
          res.data.groupId.forEach(g=>{
            list.push([g])
          })
          this.groupIds1 = list
          this.groupIds = list
        }
      });
    },
    
    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initDetailList(currentPage);
    },
  
    // 获取会员组别
    getGroups(){
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      let param = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: this.chamberId,
      }
      api.getGroupByChamberId(param).then(res=>{
        let obj = {}
        let list = []
        let source = res.data.data.records
        source.forEach(record=>{
          if(record.groupNumber!==0){
            obj = {}
            obj.value = record.groupId
            obj.label = record.groupName
            obj.node = record.node
            this.groups.push(obj)
          }
        })
        // this.groups.push(list)
      })
    },
    
    // 通过status => name
    statusName(status){
      switch(this.eventDetail.status){
        case '1':
          this.$nextTick(()=>{
            this.$refs.status.style.color = '#89B633'
          })
          return this.$t('Paying')
        case '2':
          this.$nextTick(()=>{
            this.$refs.status.style.color = 'black'
          })
          return this.$t('Paid')
        case '3':
          this.$nextTick(()=>{
            this.$refs.status.style.color = '#B52F1E'
          })
          return this.$t('PayCancel')
      }
    },
    
    //            -------------------------------------------------增删改查用户-----------------------------------------------
    
    //            搜索用户列表
    searchUserData() {
      this.companyName
        ? (this.initDetail.companyName = this.companyName)
        : delete this.initDetail.companyName;
      this.payer
        ? (this.initDetail.payer = this.payer)
        : delete this.initDetail.payer;
      this.createTime
        ? (this.initDetail.createTime = this.createTime)
        : delete this.initDetail.createTime;
      this.initDetailList(1);
    },
  
    //   创建活动时定义自定义
    customizeOrNot(){
      if(!this.eventDetail.isCustomize){
        this.eventDetail.eventPrice = ''
        document.getElementById('eventPrice').setAttribute('placeholder',this.$t('Customized'))
      }else{
        this.eventDetail.eventPrice = this.eventPrice
        document.getElementById('eventPrice').setAttribute('placeholder',this.$t('InputMoney'))
      }
    },

    //编辑活动
    confirmSubmit() {
      if(this.myRight<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.eventDetail.currentAdmin = this.initDetail.currentAdmin
        this.$refs.createActivity.validate((valid) => {
          if (valid) {
            if (!this.eventDetail.isCustomize) this.eventDetail.eventPrice = ''
            let createActivityApi = JSON.parse(JSON.stringify(this.eventDetail))
            createActivityApi.eventDate = moment(this.eventDetail.eventDate).format("YYYY-MM-DD HH:mm:ss")
            createActivityApi.activityPaymentTime = moment(this.eventDetail.activityPaymentTime).format("YYYY-MM-DD HH:mm:ss")
            createActivityApi.whetherToParticipate = false
            delete createActivityApi.needInvoice
// 参加人员
            let group = this.groupIds.flat()
            group.indexOf('1')!==-1? delete createActivityApi.groupId : createActivityApi.groupId = group+''
            api.editActivity(createActivityApi).then(res=>{
              // console.log('res ->',res)
              this.editActivityDialog = false
              this.initDetailList(1);
              this.initEventDetail();
            })
          }
        })
      }
    },
  
    toggleCheckbox() {
      // 当复选框不可用时，点击覆盖层不会改变其选中状态
      if (!this.isDisabled) {
        this.isChecked = !this.isChecked;
      }
    },
  
    // 上一页
    upperPage() {
      this.$router.push({
        name: 'ActivityPayment',
      })
    },
    
  },
};
</script>
<style lang="scss" scoped>
.top-info {
  width: 99%;
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  //position: absolute;
  
  i{
    font-size: 40px;
  }
  
  .el-button {
    margin-top: 4px;
    height: 40px;
    line-height: 0.4;
    background-color: #425d7b;
    color: white;
    
    &:hover {
      background-color: #9fbee6 !important;
    }
  }
  
  .status-button{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    
    .el-button{
      margin-left: 20px;
    }
    
    .refund-button{
      background-color: #B52F1E;
      &:hover{
        background-color: #E05D4D !important;
      }
    }
  }
}

.mainBody {
  width: 1600px;
  margin-top: 5px;
}

.filterBarPay {
  width: 99%;
  height: 75px;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  background-color: #677d95;
  border-radius: 5px;
  color: white;
  
  input {
    width: 200px;
    height: 30px;
    border: 1px solid white;
    margin: 8px 0;
    vertical-align: middle;
    float: left;
  }
  
  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    //line-height: 75px;
  }
  
  .el-button {
    width: 200px;
    height: 50px;
    line-height: 0.4;
    /*line-height: 50px;*/
    background-color: #677d95;
    color: white;
    
    &:hover {
      background-color: #9fbee6 !important;
    }
  }
  
}
.sum-info{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
  background-color: #677d95;
  color: white;
  border-radius: 5px;
  width: 99%;
  margin: 5px auto 10px auto;
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.create-activity-dialog{
  width: 910px;
  padding: 0 30px;
  
  .el-form-item{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    
    ::v-deep .el-form-item__label{
      min-width: 180px !important;
      float: left;
      text-align:center;
    }
    
    .el-input{
      width: 500px;
    }
    
    .el-textarea{
      width: 500px;
    }
  }
  
  .per-pay{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    .el-select{
      width: 100px;
    }
    .pay-amount{
      width: 200px;
      margin: 0 20px;
      .el-input__inner{
        width: 200px!important;
      }
    }
  }
}
.group-picker{
  cursor: pointer;
  
  .el-cascader{
    width: 400px;
    
  }
}
.select_group{
  height: 60px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.checkbox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; /* 使覆盖层位于复选框上方 */
  opacity: 0; /* 使覆盖层不可见 */
  cursor: not-allowed; /* 鼠标指针显示为禁止符号 */
  pointer-events: auto; /* 阻止鼠标事件传递到下面的元素 */
}
</style>
<style lang="scss">
.el-cascader-node{
  height: 70px;
  line-height: 30px;
  outline: 0;
  
  .el-checkbox__inner{
    width: 20px;
    height: 20px;
  }
  .el-checkbox__inner::after{
    height: 10px;
    left: 7px;
  }
}

</style>

